<template>
  <component
    :is="wrapperComponent"
    class="group"
    :href="session._links?.coursePage"
    target="_blank"
  >
    <figure class="flex items-start gap-4">
      <app-user-avatar
        v-if="avatar"
        :avatar-url="session.course.teacher.avatar_url"
        :name="session.course.teacher.full_name"
        :size="avatarSize"
        tile
      />

      <figcaption class="text-sm">
        <p class="flex items-center gap-2 font-semibold">
          {{ session.course.title }}
          <slot name="appendTitle" />
          <app-icon
            v-if="session._links?.coursePage"
            class="text-subtle group-hover:text-primary"
            icon="ph:arrow-square-out"
            :size="16"
          />
        </p>
        <p>
          {{ session.course.teacher.full_name }}
          <template v-if="trainerJob">
            -
            <span class="text-subtle">
              {{ session.course.teacher.job }}
            </span>
          </template>
        </p>

        <ul v-if="chips" class="mt-1 flex flex-wrap gap-1">
          <li>
            <course-format-chip :format="session.course.format" size="small" />
          </li>
          <li>
            <course-session-date-chip :session="session" size="small" />
          </li>
        </ul>
      </figcaption>
    </figure>
  </component>
</template>

<script lang="ts" setup>
const CardLinkBackground = resolveComponent("app-card-link-background");

const properties = withDefaults(
  defineProps<{
    session: DatabaseTable<"course_sessions"> & {
      course: Pick<DatabaseTable<"courses">, "title" | "format" | "slug"> & {
        teacher: Pick<
          DatabaseTable<"teachers">,
          "avatar_url" | "full_name" | "job"
        >;
      };
      _links?: {
        coursePage?: string;
      };
    };
    avatar?: boolean;
    avatarSize?: number;
    chips?: boolean;
    trainerJob?: boolean;
  }>(),
  {
    avatarSize: 60,
    avatar: true,
    chips: true,
    trainerJob: true,
  },
);

const wrapperComponent = properties.session._links?.coursePage
  ? CardLinkBackground
  : "div";
</script>
