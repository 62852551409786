<template>
  <app-chip color="#FFE8C6" icon="ph:timer" :size="size">
    {{ label }}
  </app-chip>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    size?: "small";
    format: DatabaseCompositeType<"course_format">;
  }>(),
  {
    size: undefined,
  },
);

const label = useCourseFormatLabel(toRef(properties, "format"));
</script>
